
<template>
	<div class="sign-in">

		<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="middle">
			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{ span: 12, offset: 0 }" :xl="{ span: 6, offset: 2 }" class="col-form">
				<h1 class="mb-15">登录</h1>
				<h5 class="font-regular text-muted">请输入你的账号密码进行登录</h5>
				<!-- Sign In Form -->
				<a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit"
					:hideRequiredMark="true">
					<a-form-item class="mb-10" label="账号" :colon="false">
						<a-input v-decorator="[
							'account',
							{ rules: [{ required: true, message: '请输入你的账号!' }] },
						]" placeholder="账号" />
					</a-form-item>
					<a-form-item class="mb-5" label="密码" :colon="false">
						<a-input v-decorator="[
							'password',
							{ rules: [{ required: true, message: '请输入你的密码' }] },
						]" type="password" placeholder="密码" />
					</a-form-item>
					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button">
							登录
						</a-button>
					</a-form-item>
				</a-form>
			</a-col>
		</a-row>

	</div>
</template>

<script>
let key = 1;
import { login } from "@/api/service.js";
export default ({
	data() {
		return {
			form: this.$form.createForm(this)
		}
	},
	methods: {
		handleSubmit(e) {
			this.$message.loading({ content: '登录中...', key });

			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
					login(values).then(res => {
						localStorage.setItem('token', res.data.token);
						this.$message.success({ content: '登录成功', key, duration: 2 });
						setTimeout(() => {
							this.$router.push('/classify');
						}, 2000);
					}).catch(err => {
						this.$message.error({ content: '保存失败！' + err, key, duration: 2 });
					})
				}
			});
		},
	},
})
</script>

<style lang="scss">
body {
	background-image: url(../assets/images/sign_bg@2x.png);
	background-size: 100% 100%;
}
</style>